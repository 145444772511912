// JsFromRoutes CacheKey b6f3ea5f26ea9007bbe1c918ef9b70bc
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  action: definePathHelper('post', '/deals/:deal_id/execution/action'),
  get: definePathHelper('get', '/deals/:deal_id/execution'),
  update: definePathHelper('patch', '/deals/:deal_id/execution'),
}
