// JsFromRoutes CacheKey 79e13dbf240d662c6db053124ce852e0
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/mass-draft'),
  copy: definePathHelper('get', '/deals/:id/copy'),
  complete: definePathHelper('put', '/deals/:id/complete'),
  publish: definePathHelper('put', '/deals/:id/publish'),
  new: definePathHelper('get', '/deals/new'),
  get: definePathHelper('get', '/deals/:id'),
  update: definePathHelper('patch', '/deals/:id'),
  destroy: definePathHelper('delete', '/deals/:id'),
}
